$(document).ready(function(){
        
    //wrap custom select container around episerver select element
    $( ".Form__Element select" ).wrap( "<div class='custom-select'></div>" );

    customSelects();
    
    /*if the user clicks anywhere outside the select box,
    then close all select boxes:*/
    document.addEventListener("click", closeAllSelect);

    //
    updateCustomSelectWhenSelectChange();

    //textarea countdown
    textareaCountdown(180);
    


});

//custom selects plugin:
function customSelects(){

    if($('.epi-editContainer').length) return false; //if in episerver edit mode exit

    var parentEl, i, j, selElmnt, selectedItem, optionsDiv, optionsItem;
    /*look for any elements with the class "custom-select":*/
    parentEl = document.getElementsByClassName("custom-select");
    for (i = 0; i < parentEl.length; i++) {
    selElmnt = parentEl[i].getElementsByTagName("select")[0];
    /*for each element, create a new DIV that will act as the selected item:*/
    selectedItem = document.createElement("DIV");
    selectedItem.setAttribute("class", "select-selected");
    selectedItem.setAttribute("tabindex", "0");
    selectedItem.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
    parentEl[i].appendChild(selectedItem);
    /*for each element, create a new DIV that will contain the option list:*/
    optionsDiv = document.createElement("DIV");
    optionsDiv.setAttribute("class", "select-items select-hide");
    for (j = 1; j < selElmnt.length; j++) {
        /*for each option in the original select element,
        create a new DIV that will act as an option item:*/
        optionsItem = document.createElement("DIV");
        optionsItem.innerHTML = selElmnt.options[j].innerHTML;
        optionsItem.addEventListener("click", function(e) {
            /*when an item is clicked, update the original select box,
            and the selected item:*/
            var y, i, k, s, h;
            s = this.parentNode.parentNode.getElementsByTagName("select")[0];
            h = this.parentNode.previousSibling;
            for (i = 0; i < s.length; i++) {
            if (s.options[i].innerHTML == this.innerHTML) {
                s.selectedIndex = i;
                h.innerHTML = this.innerHTML;
                y = this.parentNode.getElementsByClassName("same-as-selected");
                for (k = 0; k < y.length; k++) {
                y[k].removeAttribute("class");
                }
                this.setAttribute("class", "same-as-selected");
                break;
            }
            }
            h.click();  
            $(s).trigger('change'); //trigger select change
        });
        optionsDiv.appendChild(optionsItem);
    }
        parentEl[i].appendChild(optionsDiv);
        selectedItem.addEventListener("click", function(e) {
            /*when the select box is clicked, close any other select boxes,
            and open/close the current select box:*/
            e.stopPropagation();
            closeAllSelect(this);
            this.nextSibling.classList.toggle("select-hide");
            this.classList.toggle("select-arrow-active");
        });
        
    }    
}

//change value of custom select when original select is updated
function updateCustomSelectWhenSelectChange(){
    $('.custom-select select').change(function(){
        var parentDiv = $(this).closest('div')
        var customSelectValue = $(parentDiv).find('.select-selected').text();
        var optionText = $(this).find('option:selected').text()
        if(optionText != customSelectValue){
            $(parentDiv).find('.select-selected').text(optionText);
            $(parentDiv).find('.select-items div').removeClass('same-as-selected');
        }
    });
}


function closeAllSelect(elmnt) {
    /*a function that will close all select boxes in the document,
    except the current select box:*/
    var x, y, i, arrNo = [];
    x = document.getElementsByClassName("select-items");
    y = document.getElementsByClassName("select-selected");
    for (i = 0; i < y.length; i++) {
        if (elmnt == y[i]) {
            arrNo.push(i)
        } else {
            y[i].classList.remove("select-arrow-active");
        }
    }
    for (i = 0; i < x.length; i++) {
        if (arrNo.indexOf(i)) {
            x[i].classList.add("select-hide");
        }
    }
}



function textareaCountdown(maxlength){
    //maxlength="180" //episerver forms does not include maxlength property
    $('textarea').attr('maxlength', maxlength);
    $('textarea').each(function(){	
        var legend = '<span class="countdown">0/'+ $(this).attr('maxlength') +'</span>'
        $(this).parent().append(legend);
            
        $(this).keyup(function(){
            var parentDiv = $(this).closest('div');
            var textlimit = $(this).attr('maxlength');		
            var tlength = $(this).val().length;	       
            remain = parseInt(tlength);
            $(parentDiv).find('span.countdown').text(remain + '/'+$(this).attr('maxlength'));
        })
    });
}