$(document).ready(function(){
    //side by side movie modal
    autoPlayYouTubeModal();

    //carousel init
    carouselPublicationsInit();

    //
    carouselTestimonialsInit();

    //tooltipster init
    tooltipInit();

    //tabs active first tab
    activateFirstTab();

});


//FUNCTION TO GET AND AUTO PLAY YOUTUBE VIDEO FROM DATATAG
function autoPlayYouTubeModal() {
    var trigger = $("body").find('[data-toggle="modal"]');
    trigger.click(function () {
        var theModal = $(this).data("target");
        var modalTitle = $(this).attr("data-modalTitle");
        var videoSRC = new URL($(this).attr("data-theVideo"));
            // videoSRCauto = videoSRC + "?autoplay=1&mute=1&enablejsapi=1",
            // videoSRCauto = videoSRC + "?autoplay=1&enablejsapi=1", //
            
        var search_params = videoSRC.searchParams;    

        search_params.set('rel', '0');
        search_params.set('showinfo', '0');
        search_params.set('autoplay', '1');
        search_params.set('enablejsapi', '1');

        videoSRC.search = search_params.toString();

        var videoSRCauto = videoSRC.toString();

            //change modal title          
            if(modalTitle == "" || modalTitle == undefined) {modalTitle = '';}
            $(theModal + ' h5').text(modalTitle);
            $(theModal + ' iframe').attr('allow', 'autoplay');
            //$(theModal + ' iframe').attr('src', videoSRC);                               
            // setTimeout($(theModal + ' iframe').attr('src', videoSRCauto), 1000);  
            $(theModal + ' iframe').attr('src', videoSRCauto)                    
            //remove video on modal closes
            $(theModal).on('hidden.bs.modal', function (e) {
                // $(theModal + ' iframe').attr('src', videoSRC);
                $(theModal + ' iframe').attr('src', '');
            });
    });
}

//activate first tab in tabs component
function activateFirstTab(){
    $('.tabs-component .nav li:first-child a').tab('show');
}



