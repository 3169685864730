    // annualized returns chart options:
    var annualized_returns_options = {
        title: {
                text: ""
            },
        legend: {
            position: "bottom",
			visible: false,
        },
        chartArea: {
            background: '#ffffff',
            //width: 1100,
            height: 500,//update for md, sm, xs
        },
        seriesDefaults: {
            type: "column",
            //stack: false,
            gap: 0.57,
            color: "#05347A",
            opacity: 0.99,
            overlay: {
                gradient: "none"
            },
        },
        series: [
        {
            spacing: -0.5,
            name: "Total Fund",
            //data: currentYear(InvestmentResultsChartData).Net, // set if the data is present on page load
            color: "#05347A",
            zIndex: 2,
        }, {
            name: "Benchmark",
            //data: currentYear(InvestmentResultsChartData).Benchmark, // set if the data is present on page load
            color: "#FF9D52",
            zIndex: 1,
        }],
        valueAxis: {
            line: {
                visible: false
            },
			labels: {
				font: "bold 18px 'Lato', Arial, Helvetica, sans-serif",
				color: "#05347A",
				format: "{0}%",
			},
			majorUnit: 5,  
            axisCrossingValue: [0, -60]
        },
        categoryAxis: [
            {
				//this category series must be invisible
				name: "series-axis",				
				categories: [0, 1, 2, 3 ,4 , 5], //categories: [1, 3, 5, 10, 20, 25, 30], // 
				color: "transparent",
				line: {
					visible: false
				},
				visible: false
            },
            {
				name: "label-axis",
				categories: ["1 Year", "3 Year", "5 Year", "10 Year", "20 Year", "30 Year"],
				//color: "#ff0000", //blue should be
				labels: {
                    font: "bold 18px 'Lato', Arial, Helvetica, sans-serif",
                    color: "#05347A", 
                    padding: {top: 20, bottom: 30},  
                    template: "#=value#"                     
                },
				majorGridLines: {
					visible: false
				},
				line: {
					visible: false
				},        
			}
		],
		plotArea: {
			background: '#ffffff'
        }, 
        tooltip: {
            visible: true,
            padding: 10,
			background: "white",
			color: "black",
			border: {
				width: 1,
				color: "black"
			},
            format: "{0}",
            position: "top",
            template:"#=formatToolTipAnnualized(series.name, category, value)#",         
            //shared: true,
            //sharedTemplate:kendo.template($("#template").html()) 
        },
        //seriesClick: onSeriesClickAnnualizedMobile,    
        xAxis: {
            visible: false
        },
    };



//-------------------------------------------------------------------------------------------------------------------------------------------

// year to date returns chart options:
var year_to_date_returns_options = {
    title: {
            text: ""
        },
    legend: {
        position: "bottom",
        visible: false,
    },
    chartArea: {
        background: '#ffffff',
        //width: 1100, // this must be disabled and calculate on load and window resize
        height: 600,//update for md, sm, xs
    },
    seriesDefaults: {
        type: "area",
        opacity: 0.99,
        area: {
            line: {
                style: "smooth"
            }
        },
    },
    series: [
    {        
        name: "Year-To-Date",
        data: [], ////data: must be modified once the page is ready
        color: "transparent", //"url(#svg-gradient)",        
        markers: { 
            type: "circle", //square //marker type
            size: 17,
            color:"white", 
            visible: true,
            background: "#042FC6",
            border: {
                width: 2,
                color: "white",
                opacity: 0.99,
              }
        },
        highlight: { //hover marker
            visual: function(e) {
                
                if (e.category == 0) return false;//if first category then do nothing
                var center = e.rect.center();
                if ( window.innerWidth < 1024 ) var circleGeometry = new kendo.geometry.Circle(center, 5);
                else var circleGeometry = new kendo.geometry.Circle(center, 8);
                var circle = new kendo.drawing.Circle(circleGeometry, {
                    fill: {
                        color: "white",
                    }, 
                    stroke:{
                        color: "#042FC6",
                        width: 2,
                    },
                });
                return circle;
            }
        },
        line: {
            color: "#042FC6",
            width: 3
        },
    }],
    valueAxis: {
        line: {
            visible: false
        },
        labels: {
            font: "bold 18px 'Lato', Arial, Helvetica, sans-serif",
            color: "#05347A",
            format: "{0}%",
            //margin: 100
        },
        majorUnit: 2,  //5
        axisCrossingValue: [0, -30],
    },
    categoryAxis: [
        {
            //this category series must be invisible
            name: "series-axis",				
            categories: [0, 1, 2, 3 ,4 ,5, 6, 7, 8, 9, 10, 11, 12, 13], 
            color: "transparent",
            line: {                
                visible:true,
                width: 3,
                color: "#000000"
            },
            majorTicks: {
                //size: 6,
                color: "transparent",
                //width: 5
            },
            visible: true
        },
        {
            name: "label-axis",
            categories: ["", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec", ""],            
            labels: {
                font: "bold 18px 'Lato', Arial, Helvetica, sans-serif",
                color: "#05347A", 
                padding: {top: 20, bottom: 30},  
                template: "#=value#"                     
            },
            majorGridLines: {
                visible: false
            },
            line: {
               visible: false //shows small lines on each category (month)            
            },               
        }
    ],
    plotArea: {
        background: '#ffffff',
    }, 
    tooltip: {
        visible: true, //true
        padding: 10,
        background: "white",
        color: "black",
        border: {
            width: 1,
            color: "black"
        },
        format: "{0}",
        position: "top",
        template:"#=formatToolTipYearToDate(series.name, category, value)#",
    },
}



   
//--------------------------------------------------------------------------------------------------------------
// document ready
//---------------------------------------------------------------------------------------------------------------
$(document).ready(function(){  

    //annualized chart
    if($("#annualized_chart").length){
        if(typeof(AnnualizedReturnsChartData) !== "undefined" && !jQuery.isEmptyObject(AnnualizedReturnsChartData) && AnnualizedReturnsChartData.length){
            annualizedCartInit();
        }
        else{
            //show error message in tab1, should have a default just in case error message is empty
            if (typeof(InvestmentResultsErrorMessage) !== "undefined")
                $('.annualized-returns-error-container').html(InvestmentResultsErrorMessage).show();
            //hide chart and table
            $('.annualized-returns-container').hide(); 
        }
    }

    //year to date chart
    if($("#annualized_chart").length){
        if(typeof(YearToDateChartData) !== "undefined" && !jQuery.isEmptyObject(YearToDateChartData) && YearToDateChartData.length){
            yearToDateChartInit();            
        }
        else{
            //show error message in tab1, should have a default just in case error message is empty
            if (typeof(InvestmentResultsErrorMessage) !== "undefined")
                $('.year-to-date-returns-error-container').html(InvestmentResultsErrorMessage).show();
            //hide chart and table
            $('.year-to-date-returns-container').hide();             
        }
    }


    //toggle graph/table functionality
    $('.graph-controls input').click(function(e){
        $('.annualized-returns-container .toggle-content').hide();
        var showElem = $(this).attr('data-toggle');        
        $("."+showElem+" ").show(); // $("[id="+showElem+"]").show();
        if(showElem=="annualized-chart") $("#annualized_chart").data("kendoChart").redraw();
    });

    //table: fixed 1st col responsive
    responsiveTableFixedCol();


});

function annualizedCartInit(){
    // set data in options var
    annualized_returns_options.series[0].data = currentYear(AnnualizedReturnsChartData).Net;
    annualized_returns_options.series[1].data = currentYear(AnnualizedReturnsChartData).Benchmark;

    //set net text year
    $("#spanNetYear").text($("#selectYearsChart").val())

    // set select year behavior
    $('#selectYearsChart').on('change', function() {  
          
        var year = this.value; 

        var div = $("#annualized-table");
        var chart = $("#annualized_chart").data("kendoChart");
        annualized_returns_options.series[0].data = currentYear(AnnualizedReturnsChartData).Net;
        annualized_returns_options.series[1].data = currentYear(AnnualizedReturnsChartData).Benchmark;
        chart.setOptions(annualized_returns_options); 
        
        $("#spanNetYear").text(year);
        var model = {
            selectedYear: year
        };
        //TODO: show loading
        $.ajax({
            async: false,
            //type: 'POST',
            url: '/AnnualizedReturnsTabBlock/GetTableData',
            data: model,    //JSON.stringify(model),
            contentType: 'application/json',
            success: function (response) {
                if (response) {
                    div.html(response);
                }
                else {
                    $('.annualized-returns-error-container').html(InvestmentResultsErrorMessage).show();
                    $('.annualized-returns-container').hide();
                }                
            },
            error: function(XMLHttpRequest, textStatus, errorThrown) { 
                //console.log("Status: " + textStatus); //console.log("Error: " + errorThrown); 
                $('.annualized-returns-error-container').html(InvestmentResultsErrorMessage).show();
                $('.annualized-returns-container').hide();
            }
        });
    });

    //update option for the window size
    $("#annualized_chart").kendoChart(responsiveOptionsUpdate(annualized_returns_options, false));

    // on window resize redraw annualized return chart
    $(window).on("resize", function(event) {    
        windowResizeChartUpdate($("#annualized_chart"), annualized_returns_options);
    });       

    // redraw chart in case was hidden and window resized
    $('.investment-results a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
        $(window).trigger('resize');
    });

    //mobile behavior click
    var chart = $("#annualized_chart").data("kendoChart");
    chart.bind("seriesClick", onSeriesClickAnnualizedMobile);    

    $(window).trigger('resize');

}

// when select a year in dropdown, retuns the correct object to draw chart data
function currentYear(AnnualizedReturnsChartData){
    var selYear = $('#selectYearsChart').val();
    var cYear = _.filter(AnnualizedReturnsChartData, function(o) { 
        return o.Year == selYear; 
    });
    return cYear[0]
}


// here customize tooltip annualized chart
function formatToolTipAnnualized(name, category, dataItem){
    
    var auxiliarCategory = [1,3,5,10,20,30];

    var tooltip1 = $('#annualized-chart').attr('data-tooltip1-label');
    var tooltip2 = $('#annualized-chart').attr('data-tooltip2-label');

    if(!tooltip1.length) tooltip1='TCDRS return was:';
    if(!tooltip2.length) tooltip2='Our benchmark return was:';

    var label = auxiliarCategory[category]; //1; //3, 5, 10, 20, 25, 30
    var asdate = currentYear(AnnualizedReturnsChartData).Year; //"2018";
    var percent = currentYear(AnnualizedReturnsChartData).Net[category]; //"1.2";        
    var alt = currentYear(AnnualizedReturnsChartData).Benchmark[category]; //"1.8";

    var html = '<div class="annualized-tooltip">';
    html += '<p><strong> 1/1/' + (parseInt(asdate) - parseInt(label)+1) + ' - 12/31/' + asdate + ' </strong><br>';
    html += tooltip1 + ' <strong>' + parseFloat(percent).toFixed(2) + '%</strong><br>';
    html += tooltip2 + ' <strong>' + parseFloat(alt).toFixed(2) + '%</strong></p>';
    html += '</div>';

    return(html);

}
//tooltip like for mobile
function onSeriesClickAnnualizedMobile(e) {
    //console.log("event :: show");
    console.log(kendo.format("Series click :: {0} ({1}): {2}", e.series.name, e.category, e.value));

    var auxiliarCategory = [1,3,5,10,20,30];

    var tooltip1 = $('#annualized-chart').attr('data-tooltip1-label');
    var tooltip2 = $('#annualized-chart').attr('data-tooltip2-label');

    if(!tooltip1.length) tooltip1='TCDRS return was:';
    if(!tooltip2.length) tooltip2='Our benchmark return was:';

    var label = auxiliarCategory[e.category]; //1; //3, 5, 10, 20, 25, 30
    var asdate = currentYear(AnnualizedReturnsChartData).Year; //"2018";
    var percent = currentYear(AnnualizedReturnsChartData).Net[e.category]; //"1.2";        
    var alt = currentYear(AnnualizedReturnsChartData).Benchmark[e.category]; //"1.8";

    var html = '<div class="annualized-tooltip shadow-in-box-sm"><a href="#"><span class="icon-close"></span></a><strong>'+label+' year</strong><br/>';
    html += '<p class="body-small"><strong> 1/1/' + (parseInt(asdate) - parseInt(label)+1) + ' - 12/31/' + asdate + ' </strong><br>';
    html += tooltip1 + ' <strong>' + parseFloat(percent).toFixed(2) + '%</strong><br>';
    html += tooltip2 + ' <strong>' + parseFloat(alt).toFixed(2) + '%</strong></p>';
    html += '</div>';

    $('.mobile-tooltip-annualized-chart').html(html);

    $('.mobile-tooltip-annualized-chart a').click(function(e){
        e.preventDefault();
        $('.mobile-tooltip-annualized-chart').html('');
    })

    
}

// here customize tooltip year-to-date chart
function formatToolTipYearToDate(name, category, dataItem){

    if (category==0) return ''; //if first category then do nothing

    var tooltip1 = $("#year-to-date-chart").attr('data-tooltip1-label');
    var percent = YearToDateChartData[category].Value;

    if(!tooltip1.length) tooltip1='TCDRS return was:';

    var html = '<div class="year-to-date-tooltip">';
    html += '<p>'+ tooltip1 +' '+ percent +'%</p>';    
    html += '</div>';

    return(html);
}

//update chart options => on resize and on load chart, or redraw wthi new year
function responsiveOptionsUpdate(options, window_resize, chartEl){

    var updatedOptions = options;
    var windowWidth = window.innerWidth;

    if (window_resize) updatedOptions.transitions = false;    

    if(windowWidth >= 1200){
        updatedOptions.valueAxis.labels.font = "bold 18px 'Lato', Arial, Helvetica, sans-serif";
        updatedOptions.categoryAxis[1].labels.font = "bold 18px 'Lato', Arial, Helvetica, sans-serif"
    }
    if(windowWidth < 1200){
        updatedOptions.valueAxis.labels.font = "bold 14px 'Lato', Arial, Helvetica, sans-serif";
        updatedOptions.categoryAxis[1].labels.font = "bold 14px 'Lato', Arial, Helvetica, sans-serif";
    }
    if(windowWidth < 992){
        updatedOptions.valueAxis.labels.font = "bold 11.6px 'Lato', Arial, Helvetica, sans-serif";
        updatedOptions.categoryAxis[1].labels.font = "bold 11.6px 'Lato', Arial, Helvetica, sans-serif";
    }
    if(windowWidth < 768){
        if( chartEl == 'annualized_chart' ){
            updatedOptions.chartArea.height = 250;
            updatedOptions.categoryAxis[1].labels.rotation = 90;
        }
        if(chartEl == 'year_to_date_chart'){
            updatedOptions.chartArea.height = 400;
            updatedOptions.categoryAxis[1].labels.rotation = 90;
        }
    } 
    else {
        if(chartEl == 'annualized_chart'){
            updatedOptions.chartArea.height = 500;   
            updatedOptions.categoryAxis[1].labels.rotation = 0;
        }
        if(chartEl == 'year_to_date_chart'){
            updatedOptions.chartArea.height = 600;   
            updatedOptions.categoryAxis[1].labels.rotation = 0;
        }
        
    }

    if( chartEl == 'year_to_date_chart' ){
        if(windowWidth >= 992){
            updatedOptions.series[0].markers.size = 17; 
            updatedOptions.series[0].markers.border.width = 2; 
        }
        if(windowWidth < 992){
            updatedOptions.series[0].markers.size = 11; 
            updatedOptions.series[0].markers.border.width = 1;    
        }
    }


    return updatedOptions;
    
}

function yearToDateChartInit(){
    var chart123 = $("#year_to_date_chart");
    // set data in options var
    year_to_date_returns_options.series[0].data = yearToDateFillData();    
    //year_to_date_returns_options.chartArea.width = chart123.closest('.bottom-section-tab-content').innerWidth();  

    //update option for the window size        
    chart123.kendoChart(year_to_date_returns_options);


    // on window resize redraw annualized return chart
    $(window).on("resize", function(event) {    
        windowResizeChartUpdate($("#year_to_date_chart"), year_to_date_returns_options);
    });

    // redraw chart in case was hidden and window resized
    $('.investment-results a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
        $(window).trigger('resize');
    });

}


function yearToDateFillData(){
    var filterData = [];

    _.forEach(YearToDateChartData, function(value, key) {
      filterData.push(value.Value);
    });

    return filterData;
}

function windowResizeChartUpdate(chartEl, dataOptions){
    //var chartDiv = $("#year_to_date_chart");
    var chartDiv = chartEl;
    var chart = chartDiv.data("kendoChart");
    
    // Temporarily disable animation, yo!
    chart.options.transitions = false;

    // Temporarily hide, then set size of chart to container (which will naturally resize itself), then show it again
    chartDiv.css({ display:"none" });
    chartDiv.css({ width: chartDiv.closest('.bottom-section-tab-content').innerWidth(), display:"block" }); 
    //set new options based on window size
    chart.setOptions(responsiveOptionsUpdate(dataOptions, true, $(chartEl).attr('id') ));
    chart.redraw();
}


//fixed 1st col responsive table
function responsiveTableFixedCol(){
    $('.responsive-fixed-col').each(function (i, val) {        
        $(this).find('tbody').scroll(function (e) {
            $(this).closest('.responsive-fixed-col').find('thead').css("left", -$(this).scrollLeft());
            $(this).closest('.responsive-fixed-col').find('thead th:nth-child(1)').css("left", $(this).scrollLeft());
            $(this).find('td:nth-child(1)').css("left", $(this).scrollLeft());
        });
        
    });

}

