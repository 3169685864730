$(function () {
    setTimeout(function () {
        $(".board-meetings .custom-select .select-items div").each(function (index) {
            $(this).click(function () {
                $('.selected-data').hide();
                $('.selected-data:eq(' + index + ')').show();
            });
        });

        // disbale select options
        $(".board-meetings .custom-select select option").each(function (i) {
            if ($(this).attr('disabled') == 'disabled') {
                $('.board-meetings .custom-select .select-items div:nth-child(' + i + ')').addClass('disable');
            }
        });
    }, 500);
});