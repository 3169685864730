//navigation.js

$(function () {
    var isMobile = window.matchMedia("only screen and (max-width: 991px)").matches;
    if (!isMobile) {
        // $('.dropdown-hoverable').hover(function(){
        //     $(this).children('[data-toggle="dropdown"]').click();
        // }, function(){
        //     $(this).children('[data-toggle="dropdown"]').click();
        // });
        $('nav.navbar .dropdown').hover(function () {
            $(this).addClass('show');
        }, function () {
            $(this).removeClass('show');
        });
        $('nav.navbar .dropdown-toggle').click(function () {
            var location = $(this).attr('href');
            window.location.href = location;
            return false;
        });
    }

    // Disable click on Mobile Navigation

    $('nav.navbar .navbar-nav .nav-item i').click(function () {
        $('.dropdown').removeClass('show');
        $(this).closest('.dropdown').addClass('show');
        return false;
    });

    $('nav.navbar .navbar-nav .nav-item i.fa-chevron-up').click(function () {
        $(this).closest('.dropdown').removeClass('show');
    });

    $("nav .search-icon").click(function () {
        $('.search-bar').removeClass('d-none');
        $("nav .search-icon").hide();
        $('nav .google-language-selector').hide();
    });
    $("nav .search-bar .close-bar").click(function () {
        $('.search-bar').addClass('d-none');
        $('.autocomplete').val('');
        $("nav .search-icon").show();
        $('nav .google-language-selector').show();
        $('.autocomplete').removeClass('change-border');
    });
    // Check search input focus
    // $( ".autocomplete" ).keypress(function() {
    //     $(this).addClass('change-border');
    //   });
    //   $( ".autocomplete" ).blur(function() {
    //     $(this).removeClass('change-border');
    //   });

    // $( ".search-bar .autocomplete" ).on( "autocompleteopen", function( event, ui ) {
    //     $(this).addClass('change-border');
    // } );
    // $( ".search-bar .autocomplete" ).on( "autocompleteclose", function( event, ui ) {
    //     $(this).removeClass('change-border');
    // } );
    $(".navbar-toggler").click(function () {
        if ($("#navbarNav").is(':visible')) {
            //alert("Menu is visble. Closing ..");
            ShowFooterAndMain();
        }
        else {
            //alert("Menu is CLOSE.  Opening ...");
            HideFooterAndMain();
            //$("html, body").animate({ scrollTop: 0 }, "slow");
        }
    });

    $(window).resize(function () {
        if ($(window).width() < 1200) {
            if ($("#navbarNav").is(':visible')) {
                HideFooterAndMain();
                //$("html, body").animate({ scrollTop: 0 }, "slow");
            }
            else {
                ShowFooterAndMain();
            }
        }
        else {
            ShowFooterAndMain();
        }
    });
});

function ShowFooterAndMain() {
    $("footer").show();
    $("main").show();
}

function HideFooterAndMain() {
    $("footer").hide();
    $("main").hide();
}