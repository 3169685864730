

function Check(e) {
    var targets = $.map($("[form-reference-type=target]"), function (v) {
        return { id: v.id, reference: $(v).attr("form-reference-id"), type: $(v).attr("form-reference-input-type") };
    });

    if (e.checked) {
        var sources = $.map($("[form-reference-type=source]"), function (v) {
            return { reference: $(v).attr("form-reference-id"), text: v.value, type: $(v).attr("form-reference-input-type") };
        });
        jQuery.each(sources, function (index, source) {
            var target = lookup(targets, 'reference', source.reference);
            if (target != null && source.type == target.type) {
                switch (source.type) {
                    case "dropdown":
                    //console.log("asd");
                    default:
                        $("#" + target.id).val(source.text);
                        $("#" + target.id).trigger('change');
                }
            }
        });
    }
    jQuery.each(targets, function (index, value) {
        $('#' + value.id).prop('readonly', e.checked);

        // if (e.checked == true) {
        //     $('#' + value.id).addClass("disabled");
        // } else {
        //     $('#' + value.id).removeClass("disabled");
        // }

        // var ie11 = !!window.MSInputMethodContext && !!document.documentMode; //ie11 detection
        // var ie10 = navigator.appVersion.indexOf("MSIE 10");
        // if(!ie11 && ie10 == -1){
        //     $('#' + value.id).prop('disabled', e.checked);
        //     $('#' + value.id).prop('readonly', e.checked);
        // }      
    });
}

function lookup(array, prop, value) {
    for (var i = 0, len = array.length; i < len; i++)
        if (array[i] && array[i][prop] === value) return array[i];
}

