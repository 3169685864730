$(document).ready(function(){

    var browserCookie = getCookie("BrowserAlertModalShow");

    if (browserCookie === "false") {
        $("#BrowserAlert").remove();
    }
    else {
        var parser = new UAParser();
        var result = parser.getResult();
        var osVersion = result.os.version;//10, 13
        var osName = result.os.name;//iOS, Windows, Android, macOS
        var device = result.device.type == undefined ? "desktop" : result.device.type;//mobile, desktop(undefined), tablet
        var browser = result.browser.name;//Chrome,Opera, IE, Android Browser
        var version = result.browser.major;//85, 6, 11
        var deviceModel = result.device.model;//iphone,iPad, 0, SM-G900P, Touch
        var deviceBrand = result.device.vendor;//Apple, Google, Motorola, Samsung, Blackberry

        var supported = false;
        $.each(browserJsonData, function (index, value) {
            if (value.Device.toLowerCase() == device.toLowerCase()
                && value.OperatingSystem.toLowerCase() == osName.toLowerCase()
                && value.Browser.toLowerCase() == browser.toLowerCase()
                && parseInt(value.MinimumVersion) <= parseInt(version.split('.')[0])) {
                supported = true;
            }
        });
        if (!supported) {
            $("#BrowserAlert").show();
        } else {
            $("#BrowserAlert").remove();
        }
        setCookieWithDate("BrowserAlertModalShow", "false");
    }


    if ($('.general-alert').length > 0) {
        // Set the display of the alert container to flex
        $('.alert-container').addClass('show')
        $('.general-alert').each(function(){
            $(this).show('fast')
        })
    }

});

function setCookieWithDate(cname, cvalue) {
    var expires = expirationDateBrowserAlert;
    document.cookie = cname + "=" + cvalue + expires + ";path=/;secure=true;";
}

function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}
