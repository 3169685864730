// var searchKeywords = [
//    "ActionScript",
//    "AppleScript",
//    "Asp",
//    "BASIC",
//    "C",
//    "C++",
//    "Clojure",
//    "COBOL",
//    "ColdFusion",
//    "Erlang",
//    "Fortran",
//    "Groovy",
//    "Haskell",
//    "Java",
//    "JavaScript",
//    "Lisp",
//    "Perl",
//    "PHP",
//    "Python",
//    "Ruby",
//    "Scala",
//    "Scheme"
//  ];

$(document).ready(function(){

    //search suggest init, header and search page
    if( typeof(searchKeywords) !== "undefined" ){
        $( ".autocomplete, .autocomplete-general" ).autocomplete({
            source: searchKeywords, 
            open: function( event, ui ) { //change border input search
                $(this).addClass('change-border');
            },
            close:  function( event, ui ) { //change border input search
                $(this).removeClass('change-border');
            },
            select: function( event, ui ) {                
                //e = $.Event('keyup');
                //e.keyCode= 13; // enter
                //$( this ).trigger(e); //trigger enter keyup     
                $(this).val(ui.item.value);
                $(this).closest('form.search-form').submit();
            }       
        });
    }

    //search items != 0
    if($('.search-list-container div.search-list-item').length){
        var elementsPerPage = 5;
        var startItem = 1;
        var totalItems = $(' .general-search-component .search-list-item').length
        var endItem = 0;
        if (totalItems >= 5) endItem = 5;
        else endItem = totalItems;           
    
        $('.search-list-container').easyPaginate({
            paginateElement: 'div.search-list-item',
            elementsPerPage: elementsPerPage,
            effect: 'default',
            firstButton: false,
            lastButton: false,
            paginationContainer: 'div.search-list-pagination',
            prevButtonText: "",
            nextButtonText: "",
        });
        
        //update message
        //1st load
        var display_text = $('.general-search-component .display-message h5').text();
        display_text = display_text.replace('{start}','<span class="start-item">1</span>').replace('{end}','<span class="end-item"></span>').replace('{total}','<span class="total-items"></span>');        
        $('.general-search-component .display-message h5').html(display_text);

        $('.general-search-component .display-message h5 .total-items').text(totalItems);
        $('.general-search-component .display-message h5 .end-item').text(endItem);

        //when use pagination
        $('.easyPaginateNav a').click(function(){
            var currentPage = $('.easyPaginateNav a.current').attr('rel');  
            $('.easyPaginateNav .prev').removeClass('disabled'); 
            $('.easyPaginateNav .next').removeClass('disabled');        
            switch (currentPage) {
                case '1':
                    var endItemMsg = $('.general-search-component .search-list-item').length
                    $('.general-search-component .display-message h5 .start-item').text('1');
                    $('.general-search-component .display-message h5 .end-item').html(endItemMsg);
                    $('.easyPaginateNav .prev').addClass('disabled');
                  break;
                case '2':
                    var endItemMsg = $('.general-search-component .search-list-item').length
                    $('.general-search-component .display-message h5 .start-item').html(elementsPerPage*1 + 1);
                    $('.general-search-component .display-message h5 .end-item').html(endItemMsg + elementsPerPage);
                  break;
                case '3':
                    var endItemMsg = $('.general-search-component .search-list-item').length
                    $('.general-search-component .display-message h5 .start-item').html(elementsPerPage*2 + 1);
                    $('.general-search-component .display-message h5 .end-item').html(endItemMsg + elementsPerPage*2);
                    $('.easyPaginateNav .next').addClass('disabled');
                  break;            
              }
        });  
        
        $('.easyPaginateNav .prev').addClass('disabled'); //initial prev disabled
    
    }
    else{//search items == 0
        //if input has text then show error message
        if( $('#searchInput').length && $('#searchInput').val().length){
            $('.general-search-component .error-message span').html($('#searchInput').val());
            $('.general-search-component .error-message').removeClass('d-none').addClass('d-block');
            $('.general-search-component .error-legend').removeClass('d-none').addClass('d-block');
            $('.general-search-component .display-message').addClass('d-none');
        }
        else{
            $('.general-search-component .display-message').addClass('d-none');
        }
    }

    
});