function VideoLibraryShowMore(category) {
    var allElements = $("[category-index=" + category + "]");
    var categoryElements = allElements.filter(function (index) {
        return $(this).hasClass("d-none");
    });

    for (var i = 0; i < (allElements.length == categoryElements.length ? 3 : categoryElements.length); i++) {
        $(categoryElements[i]).removeClass('d-none');
    }

    var showMoreButton = $("[show-more-category=" + category + "]");
    var showLessButton = $("[show-less-category=" + category + "]");

    if (allElements.length == categoryElements.length + 3) {
        showMoreButton.addClass("d-none");
        showLessButton.removeClass("d-none");
    }

    if (allElements.length <= 3) {
        showMoreButton.addClass("d-none");
        showMoreButton.parent().addClass("d-none");
    }
}

function VideoLibraryShowLess(category) {
    var allElements = $("[category-index=" + category + "]");
    var categoryElements = allElements.filter(function (index) {
        return !$(this).hasClass("d-none");
    });

    for (var i = (allElements.length == categoryElements.length ? categoryElements.length : 3); i >= 3; i--) {
        $(categoryElements[i]).addClass('d-none');
    }

    var showMoreButton = $("[show-more-category=" + category + "]");
    var showLessButton = $("[show-less-category=" + category + "]");
    
    showMoreButton.removeClass("d-none");
    showLessButton.addClass("d-none");
    
    var headingCategory = $("[category-index-header=" + category + "]");
    $("body,html").animate({scrollTop: $(headingCategory).offset().top},800);
    
}