$(document).ready(function(){
    
    var planFinderTable = {};
    if (typeof (planFinderData) !== "undefined" && typeof (planFinderInnerData) !== "undefined" && $('#plan-finder-table').length)
        getPlanFinder(10); //service, rows number
    
        
});

//get plan finder data
function getPlanFinder(rowsNumber){
    
    var placeholderText = $('#plan-finder-table').attr('data-search-placeholder');
    if(!placeholderText.length) placeholderText="County, ZIP code or Employer name"

    planFinderTable = $('#plan-finder-table').DataTable({               
        "data": planFinderData,
        "info": false, //hide table info
        "bFilter": true, //show/hide search                
        "pageLength": rowsNumber, //setup page length
        "lengthChange": false, //hide dropdown page length
        // "ordering": false,//ordering buttons
        "processing": false,  //shows wait when process takes time
        //"paging":   false, //paging is needed for load more button, should be hidden with css 
        "language": {
            search: '',
            searchPlaceholder: placeholderText
        },
        responsive: true,         
        //columns definitions: 
        "aoColumnDefs": [
            { "bSearchable": false, "aTargets": [ 2 ] }, //exclude type frmo search
            { "bSearchable": true, "bVisible": false, "aTargets": [ 4 ] }, //zip col searchable and not visible   
        ],                        
        "columns": [
            
            { "data": "EmployerName", responsivePriority: 1 }, //0
            { "data": "CountyName" }, //1
            { "data": "EmployerClassName" }, //2
            {
                "className":      'details-control',
                "orderable":      false,
                "data":           null,
                "defaultContent": '',
                responsivePriority: 1
            }, //3
            { "data": "EmployerPostalCode" }, //4                    
        ],
        "order": [[0, 'asc']], //desc                
        //function for load more button
        drawCallback: function(){           
            // Show or hide "Load more" button based on whether there is more data available
            $('#plan-finder-load-more').toggle(this.api().page.hasMore());

            //add class for last row opacity
            if($('#plan-finder-load-more').is(':visible')) $('#plan-finder-table').addClass('last-row-partial');
            else $('#plan-finder-table').removeClass('last-row-partial');

            //icon sort x positions in table heads
            sortIconsPosition();
        },   

    });

    //setup row click accordion on data table
    rowDetailsClick(planFinderTable);
    
    // load more button:
    loadMoreButton(planFinderTable);
    
    //check search results show/hide error message
    showHideErrors();
    
    //windows resize adjust columns
    $(window).on('resize', function () {
        planFinderTable.columns.adjust();
    });

    //set size to 10 once keup on search input
    $('#plan-finder-table_filter label input').on( 'keyup', function () {
        planFinderTable.page.len( rowsNumber ).draw();
    } );

}

//setup row click accordion on data table
function rowDetailsClick(planFinderTable){    
    $('#plan-finder-table tbody').on('click', 'td.details-control', function () {           
        var tr = $(this).closest('tr');
        var row = planFinderTable.row( tr );

        if ( row.child.isShown() ) {
            row.child.hide();
            tr.removeClass('shown');     
        }
        else
            {
            if ( planFinderTable.row( '.shown' ).length ) {
                    $('.details-control', planFinderTable.row( '.shown' ).node()).click();
            }
            row.child(format(row.data(), planFinderInnerData) ).show();                    
            tr.next('tr').addClass('details-row');
            tr.addClass('shown');
        }
    });
}

//load more button
function loadMoreButton(planFinderTable){   
    // Handle click on "Load more" button    
    $('#plan-finder-load-more').on('click', function(e){  
        e.preventDefault();
        // Load more data
        planFinderTable.page.loadMore();
    });
}

//show/hide errors on search
function showHideErrors(){
    $('#plan-finder-table_filter input').on( 'keyup', function () {
        if ( $('#plan-finder-table .dataTables_empty').length ) {
            //console.log( 'Empty table' );
            $('#plan-finder-table').hide();
            

            if( $('#search-results-not-found').attr('data-no-results-term').length ) var textData = $('#search-results-not-found').attr('data-no-results-term');
            else var textData = "No results found for:"

            $('#search-results-not-found h5').text(textData + ' "'+ $(this).val() + '"');  // $('#search-results-not-found h5')
            $('#search-results-not-found').show();
        }
        else{
            $('#search-results-not-found h5').text('');
            $('#search-results-not-found').hide()
            $('#plan-finder-table').show("fast");
            //after show execute js to position sort icons
            sortIconsPosition();
        }
    }); 

    //show table hide errors using chrome/ie clear button
    $('#plan-finder-table_filter input').on( 'search change mouseup', function () {
        var _self = this;
        setTimeout(function() {
            if ( $(_self).val().length == 0) {
                $('#search-results-not-found').hide();
                $('#plan-finder-table').show("fast");
                sortIconsPosition();
                $('#plan-finder-table tbody tr.shown td.details-control').click(); //close opened rows
            }
        }, 1);

        
    });
}


/* Formatting function for row details - modify as you need */
function format (data , innerData) {
    // `d` is the original data object for the row
    var innerDesc = "";
    innerData.InnerDescription.forEach(function callback(currentVal, index) {
        var titleValue = index == 0 ? data.EmployeeContributionRate : index == 1 ? data.ServiceMatchingRate : index == 2 ? data.InterestRate : index == 3 ? data.VestAge60Year : "";
        var titleUnit = currentVal.Unit.length > 1 ? " "+ currentVal.Unit : currentVal.Unit;
        innerDesc += '<div class="col-md-6 text-center">' +
            '<h3>'  + titleValue + titleUnit + '</h3>' +            
            '<h4 class="eyebrow">' + currentVal.Title + '</h4>' +
            '<p>' + currentVal.Description + '</p>' +
            '</div>';           
    });
    var innerTxt = innerData.InnerText.replace("{VestAge60Year}", data.VestAge60Year).replace("{RetireAnyAgeYear}", data.RetireAnyAgeYear).replace("{RuleOfYear}", data.RuleOfYear);
    return '<div class="row plan-details ">' +
        '<div class="col-md-12 text-center accordion-content-title mb-4">' +
            '<h4>'+data.EmployerName+' '+innerData.InnerTitle+'</h4>' +
        '</div>' +
        innerDesc + innerTxt;
}

//text width, used to calculate x-position of sort icons
//extend jquery with textWidth func
$.fn.textWidth = function(){
    var html_org = $(this).text();
    var html_calc = '<span>' + html_org + '</span>';
    $(this).html(html_calc);
    var width = $(this).find('span:first').width();
    $(this).html(html_org);
    return width;
};

//set the position of sort icons in columns heads
function sortIconsPosition(){
    $('#plan-finder-table thead tr th').each(function(idx, ele) {
        var xPos = $(ele).textWidth() + 20;
        $(ele).css('background-position-x',  xPos + 'px')
    });
}

