function carouselPublicationsInit(){
    $('.carousel').slick({
        adaptiveHeight: true,
		slidesToShow: 1,
		slidesToScroll: 1,
        mobileFirst: true,
        arrows: false,
        dots: true,
        responsive: [
            {
                breakpoint: 767,
                settings: 'unslick'
            }
        ]
    });

    $(window).on('resize', function() {
        $('.carousel').slick('resize');
    });
}

function carouselTestimonialsInit(){

    $('.carousel-testimonials').slick({
        adaptiveHeight: true,
        autoplay: true,        
		slidesToShow: 1,
		slidesToScroll: 1,
        arrows: false,
        dots: true,        
    });
}


