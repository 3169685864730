var allCards = {};
var displayCards = {};
var chunkCards = {};
var chunkSize = $('.library-search-results').attr('data-show');;
var chunkPages;
var source, template, html;
var currPag;
var totalCards;
var objResult = {};
var url = $('.library-search-results').attr('data-url');
var topicFilter = "",
    typeFilter = "",
    audienceFilter = "";
var topicOptions = [];
var typeOptions = [];
var audienceOptions = [];
var objFilter = {};
var topicClick = false;
var typeClick = false;
var audienceClick = false;
var topicSelected = false;
var typeSelected = false;
var audienceSelected = false;
var allTopics = [];
var allTypes = [];
var allAudiences = [];
var objValues = {};
var topicValues = [];
var typeValues = [];
var audienceValues = [];


//***** Update Card Results *****//
function getLibraryCards() {
    showLoader();
    // allCards = libraryData; //original, show as it comes in the array
    // allCards = _.orderBy(libraryData, ['Date', 'Title'], ['desc', 'asc']);
    allCards = orderCardsBy(libraryData);
    localStorage.setItem('cardsStored', JSON.stringify(allCards));
    allCardsStored = JSON.parse(localStorage.getItem('cardsStored'));
    getAllOptions(allCardsStored);
    updateCards(allCards);
}
// Update Card Display
function updateCards(totalResults) {
    currPage = 0;
    chunkCards = _.chunk(totalResults, [size = chunkSize]);
    chunkPages = _.size(chunkCards);
    displayCards = chunkCards[0];
    //console.log('Total Results:', allCards, "\nChunk Results", chunkCards, chunkPages);
    hideLoader();
    moreBtnDisplay();
    // updateTemplate("#library-card-results", ".library-search-results", displayCards);
    // updateDropdowns(totalResults); //receive results from the update

    updateDropdowns(totalResults); //receive results from the update
    updateTemplate("#library-card-results", ".library-search-results", displayCards);

}


// Update Handlebars Template
function updateTemplate(hbs, render, dataObj) {

    //substring for cards description
    $.each(dataObj, function (index, value) {
        if (value.Description !== undefined && typeof libraryDescriptionMaxChars !== "undefined")
            if (value.Description.length >= 3 && value.Description.slice(value.Description.length - 3, value.Description.length) != "...")
                value.Description = value.Description.substr(0, libraryDescriptionMaxChars) + "...";

        if (value.Description !== undefined && (typeof libraryDescriptionMaxChars === "undefined" || libraryDescriptionMaxChars == 0))
            if (value.Description.length >= 3 && value.Description.slice(value.Description.length - 3, value.Description.length) != "...")
                value.Description = value.Description.substr(0, 130) + "...";
    });

    source = $(hbs).html();
    template = Handlebars.compile(source);
    html = template(dataObj);
    $(render).empty().append(html);
    //console.log('Results Object:', dataObj);
    autoPlayYouTubeModal();
}

// Get All Options on Load
function getAllOptions(allResults) {
    console.log('getalloptions executed');
    allAudiences = [];
    allTopics = [];
    allTypes = [];

    _.forEach(allResults, function (value, i) {
        allTypes.push(allResults[i].Type);
        allAudiences.push(allResults[i].Audience);
        _.forEach(allResults[i].Topics, function (value, j) {
            allTopics.push(allResults[i].Topics[j]);
        });
    });
    allAudiences = _.uniqBy(_.sortBy(allAudiences, 'Name'), 'Name');
    allTopics = _.uniqBy(_.sortBy(allTopics, 'Name'), 'Name');
    allTypes = _.uniqBy(_.sortBy(allTypes, 'Name'), 'Name');


    console.log('allTopics: ', allTopics);
    console.log('allTypes: ', allTypes);
}

// Get All Values for Dropdown Values
function getDropdownValues(objValues) {
    //console.log('getDropdownValues -> objValues', objValues);
    topicValues = [];
    typeValues = [];
    audienceValues = [];
    _.forEach(objValues, function (value, i) {
        typeValues.push(objValues[i].Type);
        audienceValues.push(objValues[i].Audience);
        _.forEach(objValues[i].Topics, function (value, j) {
            topicValues.push(objValues[i].Topics[j]);
        });
    });
    topicValues = _.uniqBy(_.sortBy(topicValues, 'Name'), 'Name');
    typeValues = _.uniqBy(_.sortBy(typeValues, 'Name'), 'Name');
    audienceValues = _.uniqBy(_.sortBy(audienceValues, 'Name'), 'Name');

    //console.log('audienceValues: ', audienceValues);
    //console.log('topicValues: ', topicValues);
    //console.log('typeValues: ', typeValues);
}

function getTopicValues(type, audience) {
    var resTopics = [];
    var objFilterTopic = type == "" && audience != "" ? {
        "Audience": {
            Name: audience
        }
    } :
        type != "" && audience == "" ? {
            "Type": {
                Name: type
            }
        } : type == "" && audience == "" ? {} : {
            "Audience": {
                Name: audience
            },
            "Type": {
                Name: type
            }
        };

    objTopics = _.filter(allCards, objFilterTopic);
    _.forEach(objTopics, function (value, i) {
        _.forEach(objTopics[i].Topics, function (value, j) {
            resTopics.push(objTopics[i].Topics[j]);
        });
    });
    topicValues = _.uniqBy(_.sortBy(resTopics, 'Name'), 'Name');
    updateTemplate("#topic-dropdown", ".topic-filter", topicValues);
}

function getTypeValues(topic, audience) {
    var resTypes = [];
    var objFilterType = topic == "" && audience != "" ? {
        "Audience": {
            Name: audience
        }
    } :
        topic != "" && audience == "" ? {
            "Topics": [{
                Name: topic
            }]
        } : topic == "" && audience == "" ? {} : {
            "Audience": {
                Name: audience
            },
            "Topics": [{
                Name: topic
            }]
        };

    objTypes = _.filter(allCards, objFilterType);
    _.forEach(objTypes, function (value, i) {
        resTypes.push(objTypes[i].Type);
    });
    typeValues = _.uniqBy(_.sortBy(resTypes, 'Name'), 'Name');
    updateTemplate("#type-dropdown", ".type-filter", typeValues);
}

function getAudienceValues(topic, type) {
    var resAudiences = [];
    var objFilterAudience = topic == "" && type != "" ? {
        "Type": {
            Name: type
        }
    } :
        topic != "" && type == "" ? {
            "Topics": [{
                Name: topic
            }]
        } : topic == "" && type == "" ? {} : {
            "Type": {
                Name: type
            },
            "Topics": [{
                Name: topic
            }]
        };

    objAudiences = _.filter(allCards, objFilterAudience);
    _.forEach(objAudiences, function (value, i) {
        resAudiences.push(objAudiences[i].Audience);
    });
    audienceValues = _.uniqBy(_.sortBy(resAudiences, 'Name'), 'Name');
    updateTemplate("#audience-dropdown", ".audience-filter", audienceValues);
}

// Updated Dropdown Options
function updateDropdowns(items) {
    //console.log('items.length:', items.length);
    // Fist Load Dropdown 
    //if (topicFilter == "" && typeFilter == "" && audienceFilter == "") {
    getDropdownValues(items);
    updateTemplate("#topic-dropdown", ".topic-filter", topicValues);
    updateTemplate("#type-dropdown", ".type-filter", typeValues);
    updateTemplate("#audience-dropdown", ".audience-filter", audienceValues);
    //}

    // if (typeClick == true || audienceClick == true) {
    //     getTopicValues(typeFilter, audienceFilter);
    // }
    // if (topicClick == true || audienceClick == true) {
    //     getTypeValues(topicFilter, audienceFilter);
    // }
    // if (topicClick == true || typeClick == true) {
    //     getAudienceValues(topicFilter, typeFilter);
    // }


    updateTitle();
}


function moreBtnDisplay() {
    if (chunkPages <= 1) {
        $('.load-more').addClass('d-none');
    } else {
        $('.load-more').removeClass('d-none');
    }
}

// Load More Cards
function loadMore() {
    currPage++;
    if (currPage < chunkPages) {
        displayCards = _.concat(displayCards, chunkCards[currPage])
        updateTemplate("#library-card-results", ".library-search-results", displayCards);
        //console.log(currPage, displayCards);
        if (currPage == chunkPages - 1) {
            $('.load-more').addClass('d-none');
        }
    }
}
// Display Loader
function showLoader() {
    $('.loader').removeClass('d-none');
}

function hideLoader() {
    $('.loader').addClass('d-none');
}



//****** document ready!!! *********//
// Render Results when container is present
$(function () {
    if ($('.library-search-results').length) {
        getLibraryCards();
        filterTopicUrl();
    }

    //functionality for input search text
    $('#library-search-icon').click(function (e) {
        e.preventDefault();
        $(this).closest('.dropdown-container').toggle();
        $('.input-field-search-container').toggle();
        $('#librarySearchInput').focus();
    });
    //functionality for close bar input search
    $('.input-field-search-container .close-bar').click(function (e) {
        e.preventDefault();
        $('.dropdown-container').toggle();
        $('.input-field-search-container').toggle();
    });

    //functionality in key press input search library
    $('#librarySearchInput').on('keypress', function (e) {
        if (e.which == 13 && $(this).val().length >= 3) {
            //console.log('You pressed enter!');

            //reset dropdown filters
            objFilter = {};
            topicFilter = typeFilter = audienceFilter = "";
            topicClick = typeClick = audienceClick = false;
            topicSelected = typeSelected = audienceSelected = false;

            //
            var filteredBySearch = filterBySearchInput(allCards);
            getAllOptions(filteredBySearch); //
            updateCards(filteredBySearch); //shows filtered cards
            filterBy(topicFilter, typeFilter, audienceFilter);
            $('.dropdown-container').toggle();
            $('.input-field-search-container').toggle();
        }
    });


});

function filterBySearchInput(searchObj) {
    return _.filter(allCards, function (obj) {
        var searchText = $('#librarySearchInput').val();
        return obj.Title.toLowerCase().indexOf(searchText.toLowerCase()) !== -1 || obj.Description.toLowerCase().indexOf(searchText.toLowerCase()) !== -1;
    });
}


//***** Dropdown Filters Functionality *****//

// Filter Dropdowns Functions

function filterBy(topic, type, audience) {
    topicFilter = topic;
    typeFilter = type;
    audienceFilter = audience;

    // Filter by Topic only
    if (topic != "" && type == "" && audience == "") {
        objFilter = {
            "Topics": [{
                Name: topic
            }]
        }
    }
    // Filter by Type only
    else if (type != "" && topic == "" && audience == "") {
        objFilter = {
            "Type": {
                Name: type
            }
        }
    }
    // Filter by Audience only
    else if (audience != "" && topic == "" && type == "") {
        objFilter = {
            "Audience": {
                Name: audience
            }
        }
    }
    // Filter by Type and Audience
    else if (topic == "" && type != "" && audience != "") {
        objFilter = {
            "Type": {
                Name: type
            },
            "Audience": {
                Name: audience
            }
        }
    }
    // Filter by Topic and Audience
    else if (type == "" && topic != "" && audience != "") {
        objFilter = {
            "Topics": [{
                Name: topic
            }],
            "Audience": {
                Name: audience
            }
        };
    }
    // Filter by Topic and Type
    else if (audience == "" && topic != "" && type != "") {
        objFilter = {
            "Topics": [{
                Name: topic
            }],
            "Type": {
                Name: type
            }
        };
    }
    // Filter by All
    else if (topic != "" && type != "" && audience != "") {
        objFilter = {
            "Topics": [{
                Name: topic
            }],
            "Type": {
                Name: type
            },
            "Audience": {
                Name: audience
            }
        }
    }
    // When all filters are empty
    else {
        objFilter = {};
    }



    //if inpusearch filled
    if ($('#librarySearchInput').val().length !== 0) {
        var filteredBySearch = filterBySearchInput(allCards)
        objResult = _.filter(filteredBySearch, objFilter);
        //console.log('objResult.length: ', objResult.length);
        //console.log('objFilter: ', objFilter);
    }
    else {
        objResult = _.filter(allCards, objFilter);
        //console.log('objResult.length: ', objResult.length);
        //console.log('objFilter: ', objFilter);
    }


    //Display Topic selection on card
    if (topicFilter != "") {
        var topic = _.find(allTopics, {
            Name: topicFilter
        });
        _.forEach(objResult, function (value, i) {
            objResult[i].TopicCardName = topic.Name;
            objResult[i].TopicCard = topic.DisplayName;
            objResult[i].TopicCardColor = _.find(allTopics, {
                "Name": topicFilter
            }).DropdownColor;
        });
        $('.topic-filter .dropdown button').html('<span style="background-color:' + topic.DropdownColor + '"></span>' + topic.DisplayName);
    }


    //  else {
    //     if (topicSelected == true) {
    //         $('.topic-filter .dropdown button').text('View All');
    //     }
    // }



    // No Results Found
    if (_.isEmpty(objResult) == true) {
        $('.no-results, #library-feature-content').removeClass('d-none');
        $('.results-title, .load-more').addClass('d-none');
        $('.library-search-results').empty();
    } else {
        $('.no-results, #library-feature-content').addClass('d-none');
        $('.results-title').removeClass('d-none');
        // updateCards(objResult);
        getAllOptions(objResult); //
        updateCards(objResult);

        //console.log("Search Term:", objFilter, "Object Result:", objResult);
    }

    // Selected Values on Dropdowns
    if (topicFilter == "") {
        if (topicSelected == true) {
            $('.topic-filter .dropdown button').text('View All');
        }
    } else {
        var topic = _.find(allTopics, {
            Name: topicFilter
        });
        $('.topic-filter .dropdown button').html('<span style="background-color:' + topic.DropdownColor + '"></span>' + topic.DisplayName);
    }

    //
    if (typeFilter == "") {
        if (typeSelected == true) {
            $('.type-filter .dropdown button').text('View All');
        }
    } else {
        var type = _.find(allTypes, {
            "Name": typeFilter
        });
        $('.type-filter .dropdown button').html('<img src="' + type.DropdownIcon + '"/>' + type.DisplayName);
    }

    //
    if (audienceFilter == "") {
        if (audienceSelected == true) {
            var allIcon = $('.audience-filter .dropdown .dropdown-menu li:first-child img').attr('src')
            $('.audience-filter .dropdown button').html('<img src="' + allIcon + '"/>All Users');
        }
    } else {
        var audience = _.find(allAudiences, {
            "Name": audienceFilter
        });
        $('.audience-filter .dropdown button').html('<img src="' + audience.DropdownIcon + '"/>' + audience.DisplayName);
    }
    if (audienceFilter == "" && audienceClick == true) {
        $('.audience-filter .dropdown button').html($('.audience-filter .dropdown .dropdown-menu li:first-child').html());
    }
}

// Update Results Title
function updateTitle() {
    var parent = $('.results-title');
    var title = $('.results-title h5');
    var inputSearch = $('#librarySearchInput').val();
    if (topicFilter != "") {
        var topic = _.find(allTopics, {
            Name: topicFilter
        });
        title.text(topic.DisplayName);
        parent.find('h3').addClass('d-none');
    } else if (typeFilter != "") {
        var type = _.find(allTypes, {
            "Name": typeFilter
        });
        title.text(type.DisplayName);
        parent.find('h3').addClass('d-none');
    } else if (audienceFilter != "") {
        var audience = _.find(allAudiences, {
            "Name": audienceFilter
        });
        title.text(audience.DisplayName);
        parent.find('h3').addClass('d-none');
    }
    else {
        title.text("Most Recent");
        parent.find('h3').addClass('d-none');
    }

    if (inputSearch.length != 0) {
        //console.log('inputSearch: ', inputSearch);
        parent.find('h3').removeClass('d-none')
        parent.find('h3 span').text('"' + inputSearch + '"');
        title.text("Search results for: ");
    }
}

// Reset Filters
function resetFilters() {
    objFilter = {};
    topicFilter = typeFilter = audienceFilter = "";
    topicClick = typeClick = audienceClick = false;
    topicSelected = typeSelected = audienceSelected = false;
    $('#librarySearchInput').val('');
    updateCards(allCardsStored);
    $('.no-results').addClass('d-none');
    $('.results-title').removeClass('d-none');
}

function resetInputSearch() {
    $('#librarySearchInput').val('');
    filterBy(topicFilter, typeFilter, audienceFilter);
    $('.no-results').addClass('d-none');
    $('.results-title').removeClass('d-none');
}

// Filter Topic page by Url param

String.prototype.capitalize = function () {
    // return this.charAt(0).toUpperCase() + this.slice(1).replace(/(^|[\s-])\S/g);
    var camel = this.toLowerCase().split('-');
    var str = [];
    _.forEach(camel, function (value, i) {
        value.charAt(0).toUpperCase();
        str.push(value.charAt(0).toUpperCase() + value.slice(1));
    });
    return _.join(str, '-');
}

function getUrlVars() {
    var vars = {};
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
        vars[key] = value;
    });
    return vars;
}

function filterTopicUrl() {
    var topic, type, audience;
    var topics = [];
    var types = [];
    var audiences = [];
    var URL = location.search;
    _.forEach(allTopics, function (value, i) {
        topics.push(value.Name);
    });
    _.forEach(allTypes, function (value, i) {
        types.push(value.Name);
    });
    _.forEach(allAudiences, function (value, i) {
        audiences.push(value.Name);
    });

    if (getUrlVars()["topic"] == undefined) {
        topic = "";
    } else {
        topic = getUrlVars()["topic"].capitalize();
    }
    if (getUrlVars()["type"] == undefined) {
        type = "";
    } else {
        type = getUrlVars()["type"].capitalize();
    }
    if (getUrlVars()["audience"] == undefined) {
        audience = "";
    } else {
        audience = getUrlVars()["audience"].capitalize();
    }
    if (_.includes(topics, topic) || _.includes(types, type) || _.includes(audiences, audience)) {
        checkTopicClick();
        filterBy(topic, type, audience);
    }
}

// Check Selected Dropdown
function checkAudienceClick() {
    audienceSelected = true;
    topicClick = false;
    typeClick = false;
    audienceClick = true;
}

function checkTopicClick() {
    topicSelected = true;
    topicClick = true;
    typeClick = false;
    audienceClick = false;
}

function checkTypeClick() {
    typeSelected = true;
    topicClick = false;
    typeClick = true;
    audienceClick = false;
}

function orderCardsBy(collection, param1, param2, order1, order2) {

    // recibir un objeto de propiedades con valores y eso convertir a dos arrays para pasar al orderby
    _.orderBy(collection, ['Date', 'Title'], ['desc', 'asc']);
}

// orderObj is defined as global js (B23-library-search)
// has the properties and values to perform the order of the passed collection (libraryData)
function orderCardsBy(collection) {
    var arrWithDate = _.filter(collection, 'Date');
    var arrWithoutDate = _.filter(collection, ['Date', '']);
    //order by date
    var arrWithDate = arrWithDate.sort(function (a, b) {
        var adate = new Date(b.Date),
            bdate = new Date(a.Date),
            rv = adate - bdate;
        if (rv === 0) { //order by title if same date
            rv = a.Title.localeCompare(b.Title);
        }
        return rv;
    });

    if (typeof (orderObj) != 'undefined' && !jQuery.isEmptyObject(orderObj)) {
        arrWithoutDate = _.orderBy(arrWithoutDate, Object.keys(orderObj), Object.values(orderObj));
        return _.union(arrWithDate, arrWithoutDate);
    }
    else {
        arrWithoutDate = _.orderBy(arrWithoutDate, ['Title'], ['asc']);
        return _.union(arrWithDate, arrWithoutDate);
    }
}