$(document).ready(function () {
    if ($(".g-date-mask").length) {
        $(".g-date-mask").mask('00/00/0000');
    }
    if ($(".g-phone-mask").length) {
        $(".g-phone-mask").mask('(000) 000-0000');
    }
    if ($(".g-zip-mask").length) {
        $(".g-zip-mask").mask('00000');
    }
});
